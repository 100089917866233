import {dynamicWrapper, createRoute} from '@/utils/core';
import Routers from '@/layouts/Routers';
import NotFound from '@/routes/Pages/404';
import Property from './routers/PropertyClassify';
import DataProperty from './routers/DataProperty';
import Labels from './routers/Labels';
import PropertyModel from './routers/PropertyModel';
import Trends from './routers/Trends';
import DataPropertyMap from './routers/DataPropertyMap';
import DataQueryPath from './routers/DataQueryPath'
import point from './routers/point/router'


const routesConfig = (app) => ({
  path: '/property',
  title: '资产管理',
  component: Routers,
  exact: false,
  indexRoute: '/property/propertyClassify',
  childRoutes: [
    Property(app),
    DataProperty(app),
    Labels(app),
    PropertyModel(app),
    Trends(app),
    DataPropertyMap(app),
    DataQueryPath(app),
    point(app),
    NotFound(),
  ]
});

export default (app) => createRoute(app, routesConfig);

import {dynamicWrapper, createRoute} from '@/utils/core';
import Routers from '@/layouts/Routers';
import NotFound from '@/routes/Pages/404';
import Template from './routers/Template';
import File from './routers/File';

const routesConfig = (app) => ({
  path: '/privateForms',
  title: '私有报表管理',
  component: Routers,
  exact: false,
  indexRoute: '/privateForms/index',
  childRoutes: [
    Template(app),
    File(app),
    NotFound(),
  ]
});

export default (app) => createRoute(app, routesConfig);
